<template>
	<div class="company-page-block">
		<section
			v-if="organization"
			class="section wow"
		>
			<div
				v-if="$route.fullPath === '/organization/' + $route.params.id"
				class="content"
			>
				<Breadcrumb :page-name="organization.title" />
			</div>
			<div
				v-else-if="$route.fullPath === '/organization/' + $route.params.id + '?vacancies=true'"
				class="content"
			>
				<Breadcrumb
					:page-name="'Вакансии'"
					:additional-pages="[{name: organization.title, link: '/organization/' + $route.params.id}]"
				/>
			</div>
		</section>

		<section class="section wow">
			<div
				v-if="isLoaded || !organization"
				class="preloader"
				style="margin-top: 100px"
			/>
			<template v-else>
				<div
					v-if="activImage('activ_image', organization)"
					class="company-page-banner-block"
				>
					<div class="content">
						<div
							class="company-page-banner-item"
							:style="activImage('activ_image', organization) | bgrDynamicImageCover"
						>
							<div class="company-page-banner-content">
								<h1 class="company-page-banner-title" />
								<div class="text">
									<p />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="activImage('activ_image', organization)"
					class="company-page-info-block"
				>
					<div class="content">
						<div class="bar-block">
							<div class="bar-box">
								<h1 class="company-page-info-title">
									{{ organization.title }}
								</h1>
							</div>
							<div
								v-if="activImage('activ_logo', organization)"
								class="bar-box"
							>
								<div
									class="company-page-logo"
									:style="activImage('activ_logo', organization) | bgrDynamicImageCover"
								/>
							</div>
						</div>
						<div class="company-page-info-item">
							<div class="field-result-group">
								<div class="cells">
									<div
										v-if="organization.city"
										class="cell"
									>
										<div class="field-result-item">
											<div class="field-result-label">
												Город головного офиса:
											</div>
											<div class="field-result-value">
												{{ organization.city }}
											</div>
										</div>
									</div>
									<div
										v-if="organization.phone"
										class="cell"
									>
										<div class="field-result-item">
											<label class="field-result-label">Номер телефона:</label>
											<div class="field-result-value">
												<a :href="'tel:' + organization.phone">{{ organization.phone }}</a>
											</div>
										</div>
									</div>
									<div
										v-if="organization.email"
										class="cell"
									>
										<div class="field-result-item">
											<label class="field-result-label">Email:</label>
											<div class="field-result-value">
												<a :href="'mailto:' + organization.email">{{ organization.email }}</a>
											</div>
										</div>
									</div>
									<div class="cell full">
										<div class="field-result-item">
											<label class="field-result-label">Сфера деятельности:</label>
											<div
												v-if="organization.field_activity.length"
												class="tag-group"
											>
												<div
													v-for="activity in organization.field_activity"
													:key="activity.id"
													class="tag-item"
												>
													{{ activity.name }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else
					class="company-page-info-block no-padding"
				>
					<div class="content">
						<div class="bar-block">
							<div class="bar-box">
								<h1 class="company-page-info-title">
									{{ organization.title }}
								</h1>
							</div>
							<div
								v-if="activImage('activ_logo', organization)"
								class="bar-box"
							>
								<div
									class="company-page-logo"
									:style="activImage('activ_logo', organization) | bgrDynamicImageCover"
								/>
							</div>
						</div>
						<div class="company-page-info-item">
							<div class="field-result-group">
								<div class="cells">
									<div
										v-if="organization.city"
										class="cell"
									>
										<div class="field-result-item">
											<div class="field-result-label">
												Город головного офиса:
											</div>
											<div class="field-result-value">
												{{ organization.city }}
											</div>
										</div>
									</div>
									<div
										v-if="organization.phone"
										class="cell"
									>
										<div class="field-result-item">
											<label class="field-result-label">Номер телефона:</label>
											<div class="field-result-value">
												<a :href="'tel:' + organization.phone">{{ organization.phone }}</a>
											</div>
										</div>
									</div>
									<div
										v-if="organization.email"
										class="cell"
									>
										<div class="field-result-item">
											<label class="field-result-label">Email:</label>
											<div class="field-result-value">
												<a :href="'mailto:' + organization.email">{{ organization.email }}</a>
											</div>
										</div>
									</div>
									<div class="cell full">
										<div class="field-result-item">
											<label class="field-result-label">Сфера деятельности:</label>
											<div
												v-if="organization.field_activity.length"
												class="tag-group"
											>
												<div
													v-for="activity in organization.field_activity"
													:key="activity.id"
													class="tag-item"
												>
													{{ activity.name }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</section>

		<section
			id="organizationTabs"
			class="section wow"
		>
			<div class="content">
				<div>
					<div class="tabs">
						<router-link
							:to="'/organization/' + $route.params.id"
							:class="{ 'active': organizationPage == 'about' }"
						>
							<span>Описание</span>
						</router-link>
						<router-link
							:to="'/organization/' + $route.params.id + '?vacancies=true'"
							:class="{ 'active': organizationPage == 'Vacancies' || organizationPage == 'Vacancies' }"
						>
							<span>Вакансии</span>
						</router-link>
					</div>
				</div>
			</div>
		</section>
		<component :is="organizationPage" />
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import About from './About'
import Vacancies from './Vacancies'
import Breadcrumb from '@/components/Breadcrumb';

export default {
    components: {
        About,
        Vacancies,
        Breadcrumb
    },
    data: () => ({
        isLoaded: true,
        currentPage: About
    }),
    computed: {
        ...mapGetters('org', {
            organization: 'getOrganization'
        }),
        organizationPage() {
            if (this.$route.query.vacancies) return 'Vacancies'
            else return 'about'
        }
    },
    created() {
        this.getOrganization(this.$route.params.id).then(() =>  this.isLoaded = false)
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'));
    },
    methods: {
        ...mapActions('org', [
            'getOrganization'
        ]),
        activImage(type, activ) {
            if (type === 'activ_logo' && activ.image_activ.length) {
                let result = activ.image_activ.find(image => image.type === 'activ_logo')
                return result && result.image
            } else if (type === 'activ_image' && activ.image_activ.length) {
                let result = activ.image_activ.find(image => image.type === 'activ_image')
                return result && result.image
            }
            return false
        }
    }
}
</script>

<style lang="less">
.company-page-info-block.no-padding {
    padding-top: 60px;
}
.icon-back{
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 11px;
    margin-right: 12px;
    &:before{
        background: url(../../assets/img/icons/icon-angle-back.png) center center no-repeat;
    }
    &:after{
        background: url(../../assets/img/icons/icon-angle-back-white.png) center center no-repeat;
    }
}

.company-page-block{
    padding-bottom: 20px;
    .section{
        margin-bottom: 50px;
        &.blue{
            padding: 50px 0;
            background: #F1F6FD;
        }
    }
    .tabs{
        margin-top: -20px;
    }
    .vacancies-sidebar{
        background: @color-light-blue;
    }
    .search{
        .search-field{
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                padding-right: 0;
            }
        }
    }
}

.company-page-banner-block{
    margin-top: -40px;
    margin-bottom: -125px;
}
.company-page-banner-item{
    height: 248px;
    position: relative;
    z-index: 20;
    .cover;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        background-size: contain;
        height: 160px;
    }
    @media @mobile-end{
        margin-left: -15px;
        margin-right: -15px;
    }
}
.company-page-banner-content{
    position: relative;
    z-index: 25;
    padding: 50px 70px;
    color: #fff;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        padding: 50px 60px;
    }
    @media @mobile-end{
        padding: 30px 15px;
    }
    .text{
        margin-top: 55px;
        color: #fff;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            margin-top: 45px;
        }
        @media @mobile-end{
            margin-top: 30px;
            font-size: 17px;
            br{
                display: none;
            }
        }
    }
}
.company-page-banner-title{
    word-break: break-word;
    .font-title;
    font-size: 27px;
    font-weight: 700;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        font-size: 24px;
    }
    @media @mobile-end{
    }
}

.company-page-info-block{
    padding-top: 175px;
    padding-bottom: 60px;
    background: #F1F6FD;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        padding-bottom: 55px;
    }
}
.company-page-info-title{
    margin-bottom: 10px;
    word-break: break-word;
    .font-title;
    font-size: 32px;
    font-weight: 700;
    @media @desktop-end{
    }
    @media @laptop-end{
        font-size: 43px;
    }
    @media @tablet-end{
        font-size: 40px;
    }
    @media @mobile-end{
        font-size: 32px;
    }
    @media @oldy-end{
        font-size: 30px;
    }
}
.company-page-info-subtitle{
    word-break: break-word;
    .font-title;
    font-size: 27px;
    font-weight: 700;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        font-size: 24px;
    }
    @media @mobile-end{
        font-size: 22px;
    }
}
.company-page-logo{
    width: 150px;
    height: 150px;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        margin-top: 40px;
    }
}
.company-page-info-item{
    margin-top: 10px;
    .field-result-label{
        margin-bottom: 10px;
        font-size: 18px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            font-size: 16px;
        }
        @media @mobile-end{
            margin-bottom: 2px;
        }
    }
    .field-result-value{
        font-size: 21px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            font-size: 18px;
        }
        @media @mobile-end{
        }
    }
    .field-result-group{
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            margin-bottom: -25px;
        }
        .cell{
            width: 33.33%;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
        }
    }
    .field-result-item{
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            margin-bottom: 25px;
        }
    }
    .tag-group{
        padding-top: 10px;
    }
}

.company-page-pic{
    position: relative;
    font-size: 0;
    img{
        display: block;
        width: 100%;
    }
}

.company-page-feature-block{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        display: block;
        margin-bottom: -35px;
        text-align: center;
    }
    .grid{
        margin: 0 -15px;
    }
    .box{
        width: 33.33%;
        padding: 0 15px;
    }
}
.company-page-feature-item{
    max-width: 33.33%;
    padding-right: 30px;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        max-width: 100%;
        margin-bottom: 35px;
        padding-right: 0;
    }
    .text{
        font-size: 18px;
        color: @color-text;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            font-size: 16px;
        }
        @media @mobile-end{
        }
    }
}
.company-page-feature-icon{
    height: 67px;
    line-height: 60px;
    margin-bottom: 30px;
    object-fit: contain;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        margin-bottom: 20px;
    }
    @media @mobile-end{
        margin-bottom: 10px;
    }
    img{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        max-height: 60px;
    }
}

.company-page-slider{
    margin-bottom: 0;
    .slick-arrow{
        top: 50%;
        margin-top: -45px;
        &.slick-prev{
            left: -75px;
            @media @desktop-end{
            }
            @media @laptop-end{
                left: -30px;
            }
            @media @tablet-end{
                left: -15px;
            }
            @media @mobile-end{
                left: -25px;
            }
        }
        &.slick-next{
            right: -75px;
            @media @desktop-end{
            }
            @media @laptop-end{
                right: -30px;
            }
            @media @tablet-end{
                right: -15px;
            }
            @media @mobile-end{
                right: -25px;
            }
        }
    }
    .slick-dots{
        position: static;
        margin-top: 15px;
        margin-bottom: -5px;
    }
}

.company-page-text{
    .text{
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            font-size: 17px;
        }
    }
}

.vacancies-block{
    padding-bottom: 20px;
    .grid{
        margin: 0 -30px;
    }
    .box{
        padding: 0 30px;
        &:nth-child(1){
            width: 34%;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
                width: 100%;
            }
            @media @mobile-end{
            }
        }
        &:nth-child(2){
            width: 66%;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
                width: 100%;
            }
            @media @mobile-end{
            }
        }
    }
    .pagination{
        margin-top: 32px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            text-align: center;
        }
        @media @mobile-end{
        }
    }
}
.vacancy-card-group{
    //overflow: hidden;
    //margin-bottom: -40px;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        //margin-bottom: -55px;
    }
    //.cells{
    //    margin: 0 -15px;
    //}
    //.cell{
    //    width: 50%;
    //    padding: 0 15px;
    //    @media @desktop-end{
    //    }
    //    @media @laptop-end{
    //    }
    //    @media @tablet-end{
    //    }
    //    @media @mobile-end{
    //        width: 100%;
    //        &:after{
    //            content: '';
    //            position: absolute;
    //            bottom: -1px;
    //            left: -1000px;
    //            display: block;
    //            width: 3000px;
    //            height: 1px;
    //            background: #E6ECF4;
    //        }
    //    }
    //    &:nth-child(even){
    //        &:after{
    //            content: '';
    //            position: absolute;
    //            bottom: -1px;
    //            left: -1000px;
    //            display: block;
    //            width: 3000px;
    //            height: 1px;
    //            background: #E6ECF4;
    //        }
    //        &:last-child{
    //            &:after{
    //                display: none;
    //            }
    //        }
    //    }
    //}
}

.vacancy-card-item{
    margin: 0 -30px 5px;
    padding: 25px 30px;
    border-radius: 6px;
    .transition;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        margin-bottom: 0;
    }
    @media @mobile-end{
    }
    &:hover{
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    }
    .cells{

    }
    .cell{
        &:nth-child(1){
            width: 66.66%;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
            }
        }
        &:nth-child(2){
            width: 33.33%;
            text-align: right;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
            }
            @media @mobile-end{
                width: 100%;
                text-align: left;
            }
        }
    }
    .vacancy-tag-group{
        padding-bottom: 15px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            padding-bottom: 16px;
        }
        @media @mobile-end{
        }
    }
    .vacancy-tag-item{
        margin-bottom: 15px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
        }
    }
    .text{
        margin-bottom: 20px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
        }
    }
}
.vacancy-card-title{
    margin-bottom: 19px;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
    }
}
.vacancy-card-link{
    display: inline-block;
    .font-title;
    font-size: 18px;
    font-weight: 700;
    color: @color-text !important;
    &:hover{
        span{
            border-bottom-color: rgba(204, 204, 204, 0);
        }
    }
    span{
        border-bottom: 1px solid #ccc;
        .transition;
    }
}
.vacancy-card-subtitle{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    @media @mobile-end{
        margin-bottom: 15px;
    }
}
.vacancy-card-salary{
    padding-top: 6px;
    font-size: 16px;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        margin-bottom: 15px;
        padding-top: 0;
    }
}
.vacancy-card-city{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    @media @mobile-end{
        margin-bottom: 15px;
    }
}
.vacancy-card-type{
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    color: @color-light;
    @media @mobile-end{
        margin-bottom: 20px;
    }
}

.vacancy-tag-group{
    margin-bottom: -10px;
    .vacancy-tag-item{
        margin-right: 10px;
        margin-bottom: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.vacancy-tag-item{
    display: inline-block;
    padding: 6px 17px;
    background: @color-light-blue;
    border-radius: 5px;
    font-size: 13px;
}
.vacancies-number{
    margin-bottom: 20px;
    font-size: 13px;
    color: @color-light;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        //margin-bottom: 20px;
    }
    span{
        color: @color-text;
    }
}
.vacancies-sidebar{
    padding: 5px 30px 25px;
    background: @color-light-blue;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        margin-bottom: 40px;
        padding-top: 25px;
    }
    @media @mobile-end{
        padding: 18px 18px 18px 22px;
    }
    @media @oldy-end{
        padding: 15px 18px 22px;
    }
    &.active{
        &.notempty{
            .vacancies-sidebar-title{
                &:after{
                    opacity: 0;
                }
            }
        }
    }
    &.notempty{
        .vacancies-sidebar-title{
            &:after{
                opacity: 1;
            }
        }
    }
    .field{
        &:not(:placeholder-shown){
            & + .label{
                opacity: 0;
                top: 12px;
            }
        }
    }
    .collapse{
        position: relative;
        padding-top: 18px;
        padding-bottom: 18px;
        &:after{
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: -30px;
            right: -30px;
            height: 1px;
            background: #DEE4ED;
            @media @desktop-end{
            }
            @media @laptop-end{
            }
            @media @tablet-end{
                left: -18px;
                right: -18px;
            }
            @media @mobile-end{
                left: -15px;
                right: -15px;
            }
        }
        &.active{
            .collapse-button{
                &:before{
                    .rotate(@deg: 180deg);
                }
            }
        }
    }
    .collapse-button{
        position: relative;
        padding: 0;
        .transition;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 16px;
            height: 10px;
            margin-top: -5px;
            background: url(../../assets/img/icons/icon-select-dark.svg) center center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            .transition;
        }
        &:hover{
            opacity: 0.7;
        }
    }
    .collapse-body{
        padding-top: 5px;
        padding-bottom: 15px;
    }
    .hide-button{
        margin-top: 15px;
    }
    .field-wrap{
        margin-top: 2px;
        margin-bottom: 0;
        &.sm{
            margin-bottom: 10px;
        }
    }
    .checkbox-filter{
        display: block;
        padding-top: 6px;
        font-size: 15px;
    }
}
.vacancies-sidebar-header{
    display: none;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
        display: block;
    }
    @media @mobile-end{
    }
    .cells{

    }
    .cell{
        vertical-align: middle;
        &:nth-child(1){
            width: 55%;
        }
        &:nth-child(2){
            width: 45%;
            text-align: right;
        }
    }
}
.vacancies-sidebar-title{
    position: relative;
    display: inline-block;
    padding-left: 35px;
    .font-title;
    font-size: 18px;
    font-weight: 700;
    @media @mobile-end{
    }
    @media @oldy-end{
        padding-left: 29px;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        width: 18px;
        height: 11px;
        background: url(../../assets/img/icons/icon-filter.png) center center no-repeat;
    }
    &:after{
        opacity: 0;
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 100%;
        width: 7px;
        height: 7px;
        margin-left: 6px;
        border-radius: 200px;
        background: @color-main;
        .transition;
    }
    &.notempty{
        &:after{
            opacity: 1;
        }
    }
}
.vacancies-sidebar-toggle{
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    color: @color-light;
    &:hover {
        color: #EF722E;
        text-decoration: none;
    }
}
.vacancies-sidebar-body{
    @media @laptop-start{
        display: block !important;
    }
    @media @tablet-end{
        display: none;
        padding-top: 20px;
        &.active {
            display: block;
        }
    }
    @media @mobile-end{
    }
}
.vacancies-filter-title{
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
    color: @color-text;
    cursor: pointer;
    .transition;
    .noselect;
    &:after{
        opacity: 0;
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 100%;
        width: 6px;
        height: 6px;
        margin-left: 6px;
        border-radius: 200px;
        background: @color-main;
        .transition;
    }
    &:hover{
        //color: #666;
    }
}

.vacancies-filters-toggle{
    border-bottom: 1px solid @color-light;
    font-size: 13px;
    color: @color-light;
    cursor: pointer;
    .transition;
    &:hover{
        border-bottom-color: rgba(255, 255, 255, 0);
    }
}
.vacancies-filter-clear{
    display: inline-block;
    font-size: 13px;
    border-bottom: 1px solid @color-light;
    color: @color-light;
    cursor: pointer;
    .transition;
    &:hover{
        border-bottom-color: @color-main;
        color: @color-main;
    }
}
.vacancies-filter-apply{
    width: 100%;
    margin-top: 35px;
    margin-bottom: 12px;
}

.range-slider-item{

}
.range-slider-track{
    height: 12px;
    padding-top: 4px;
    cursor: pointer;
    margin-bottom: 25px;
}
.range-slider-line{
    position: relative;
    height: 4px;
    margin-bottom: 25px;
    background: #E4ECF2;
}
.range-slider-handle{
    position: absolute;
    top: 0;
    height: 4px;
    background: @color-main;
    .transition;
}
.range-slider-circle{
    position: absolute;
    top: -4px;
    width: 12px;
    height: 12px;
    background: @color-main;
    border-radius: 50%;
    &:nth-child(1){
        left: -4px;
    }
    &:nth-child(2){
        right: -4px;
    }
}
.range-slider-value-block{
    .cells{
        margin: 0 -9px;
    }
    .cell{
        padding: 0 9px;
    }
    .field-default{
        padding-left: 32px;
    }
}
.range-slider-value-text{
    position: absolute;
    top: 3px;
    left: 11px;
    font-size: 15px;
    color: #aaa;
}
</style>

